import {dom, library} from '@fortawesome/fontawesome-svg-core'

import {
  faBasketShopping as farBasketShopping,
  faCalendarAlt as farCalendarAlt,
  faCalendarXmark as farCalendarXmark,
  faCartFlatbedBoxes as farCartFlatbedBoxes,
  faCartShopping as farCartShopping,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faEnvelope as farEnvelope,
  faExclamationTriangle as farExclamationTriangle,
  faFerry as farFerry,
  faFileChartPie as farFileChartPie,
  faFloppyDisk as farFloppyDisk,
  faGlobe as farGlobe,
  faInfoCircle as farInfoCircle,
  faMagnifyingGlass as farMagnifyingGlass,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faPhone as farPhone,
  faPlus as farPlus,
  faPrint as farPrint,
  faTrash as farTrash,
  faTruck as farTruck,
  faTruckContainer as farTruckContainer,
  faUfo as farUfo,
  faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faAngleUp as fasAngleUp,
  faBadgePercent as fasBadgePercent,
  faBars as fasBars,
  faCartCirclePlus as fasCartCirclePlus,
  faCartShopping as fasCartShopping,
  faCheck as fasCheck,
  faCircle as fasCircle,
  faCircleNotch as fasCircleNotch,
  faClock as fasClock,
  faExclamationCircle as fasExclamationCircle,
  faHouse as fasHouse,
  faInfoCircle as fasInfoCircle,
  faImagePolaroid as fasImagePolaroid,
  faLockKeyhole as fasLockKeyhole,
  faMinus as fasMinus,
  faPlus as fasPlus,
  faRotateRight as fasRotateRight,
  faPrint as fasPrint,
  faSpinnerThird as fasSpinnerThird,
  faStore as fasStore,
  faText as fasText,
  faTrashCan as fasTrashCan,
  faXmark as fasXmark,
  faExclamationTriangle as fasExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faDocker as faDocker,
  faFacebook as faFacebook,
  faInstagram as faInstagram,
  faLinkedin as faLinkedin,
  faTiktok as faTiktok,
  faYoutube as faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import * as VVIcons from './vilvite-icons'

library.add(
  // Regular
  farBasketShopping,
  farCalendarXmark,
  farCartFlatbedBoxes,
  farCartShopping,
  farCheckCircle,
  farFerry,
  farGlobe,
  farMagnifyingGlass,
  farMagnifyingGlassPlus,
  farTruck,
  farTruckContainer,
  farUfo,
  farCalendarAlt,
  farXmark,
  farFloppyDisk,
  farPrint,
  farPlus,
  farCheck,
  farPhone,
  farEnvelope,
  farTrash,
  faDocker,

  // Solid
  fasAngleDown,
  fasAngleRight,
  fasAngleUp,
  fasAngleLeft,
  fasBadgePercent,
  fasBars,
  fasCircle,
  fasCartCirclePlus,
  fasCartShopping,
  fasCircleNotch,
  fasHouse,
  fasLockKeyhole,
  fasXmark,
  fasMinus,
  fasPlus,
  fasTrashCan,
  fasStore,
  fasSpinnerThird,
  fasRotateRight,
  fasClock,
  fasInfoCircle,
  fasText,
  fasCheck,
  fasExclamationCircle,
  farFileChartPie,
  farExclamationTriangle,
  fasImagePolaroid,
  fasPrint,
  fasExclamationTriangle,

  // Brand
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTiktok,

  farInfoCircle,
  // VilVite icons
  ...Object.values(VVIcons)
)

dom.watch()
